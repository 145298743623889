<template lang="">
  <div>
    <b-card>
      <basetable
        ref="basetable"
        :baseroute="baseroute"
        :dataurl="dataurl"
        :fields="fields"
        :title="title"
        :is-view="false"
      >
        <template #cell(_)="data">
          <b-button
            size="sm"
            variant="success"
            class="mr-1 btn-icon"
            @click="editForm(data)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-if="data.item.channel != 'super'"
            size="sm"
            variant="danger"
            class="mr-1 btn-icon"
            @click="confirmDelete(data)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import {BCard, BButton} from 'bootstrap-vue'
import config from './Config.js'

export default {
  components: {
    basetable,
    BCard,
    BButton
  },
  data() {
    return {
      title:config.title,
      dataurl:config.dataulr,
      baseroute:config.baseroute,
      fields:config.fields,
    }
  },
  methods:{
    editForm(data){
      this.$router.push({ name: this.baseroute+'-edit',params : { id: data.item.id} })
    },
    confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm('Are you sure delete '+Object.values(data.item)[1]+' ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+id)
              .then(() => {
                this.$refs.basetable.fetchData()
            })
          }
        })
    },
  }
}
</script>
<style lang="">

</style>
